import React from "react";
import {Navigate, Outlet} from "react-router-dom";
import TrackPageView from "../../TrackPages";

const RouteProtection = () =>{
    const instructor_id = localStorage.getItem("instructor_id");
    const token = sessionStorage.getItem('token');
    return(
        
        instructor_id && token?
                <>
                <TrackPageView />
                <Outlet/>
                </>
                :
                <Navigate to='/'/>

    )
};
export default RouteProtection;