import  { useEffect } from "react";
import browserUpdate from "browser-update";

const BrowserUpdate = () => {
  useEffect(() => {
    browserUpdate({
        required: { e: -2, f: -3, o: -3, s: -1, c: -3 }, // Adjust required browser versions
        insecure: true, // Warn users if they are using an insecure browser
        unsupported: true, // Alert users with unsupported browsers
        text: "Your browser is outdated. Please update for a better experience.",
        reminder: 12, // Hours before reminding again
        newwindow: true, // Open update links in a new tab
        onshow: (info) => console.log("Browser update message shown", info),
      })
  }, []);

  return null;
};

export default BrowserUpdate;
