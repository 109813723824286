import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import EnglishIcon from '../../assets/icons/SubjectIcons/languageArtIcon.svg'
import MathIcon from '../../assets/icons/SubjectIcons/mathIcon.svg'
import ScienceIcon from '../../assets/icons/SubjectIcons/scienceIcon.svg'
import SocialIcon from '../../assets/icons/SubjectIcons/socialIcon.svg'
import InformaticsIcon from '../../assets/icons/SubjectIcons/InformaticsIcon.svg'



const ChooseMainTopic = ({MainTopic, mainTopicError, handleMainTopicChange }) => {
    return (
        <div className="w-full">
            <FormControl fullWidth
                sx={{
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            border: `${mainTopicError ? '2px solid #FDE4DC' : '2px solid #F3F6F6'}`,
                        },
                        '&.Mui-focused fieldset': {
                            border: '2px solid #DBE6F5',
                        },
                        '&:hover fieldset': {
                            border: '2px solid #DBE6F5',
                        },
                    },
                    width:'100%'
                }}>
                <InputLabel>Main Topic</InputLabel>
                <Select
                    labelId="MainTopic" id='MainTopic'
                    name="mainTopic"
                    label="Main Topic"
                    // value={MainTopic}
                    defaultValue={MainTopic}
                    onChange={handleMainTopicChange}
                    style={{ borderRadius: "10px", color: '#2E4EB5', background: `${MainTopic ? '#F3F6F6' : 'white'}` }}
                    renderValue={(selected) => selected}
                    sx={{width:'100%'}}
                >
                    <MenuItem value="Language Arts">
                        <span>Language Arts</span>
                        <img className="h-10 ms-[15px]" src={EnglishIcon} alt="English Icon" />
                    </MenuItem>
                    <MenuItem value="Math">
                        <span>Math</span>
                        <img className="h-10 ms-[70px]" src={MathIcon} alt="Math Icon" />
                    </MenuItem>
                    <MenuItem value="Science">
                        <span>Science</span>
                        <img className="h-10 ms-[60px]" src={ScienceIcon} alt="Science Icon" />
                    </MenuItem>
                    <MenuItem value="Social">
                        <span>Social</span>
                        <img className="h-10 ms-[70px]" src={SocialIcon} alt="Social Icon" />
                    </MenuItem>
                    <MenuItem value="Informatics">
                        <span>Informatics</span>
                        <img className="h-10 ms-[35px]" src={InformaticsIcon} alt="Informatics Icon" />
                    </MenuItem>
                </Select>
            </FormControl>
        </div>
    )
};
export default ChooseMainTopic;