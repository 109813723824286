import { Alert, Button, Dialog, DialogContent } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import TryFree from "../GlobalComponents/TryFree";


const ProtectPlanRoutes = (ActualRoute) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const questionList_id = queryParams.get('questionList_id') !== "" ? queryParams.get('questionList_id') : "";
    const navigate = useNavigate();
    const LicenceType = parseInt(localStorage.getItem("plan"));
    const Length = parseInt(localStorage.getItem("coursesLength"));
    const organization = parseInt(localStorage.getItem("organization"));
    const [dialog, setDialog] = useState(true)
    let error = false;
        if (organization !== 1) {
        if (!questionList_id) {
            if (LicenceType === 0 && Length >= 2) {
                error = true;
            }else if (LicenceType === 1 && Length >= 3) {
                error = true;
            }else if (LicenceType === 2 && Length >= 10) {
                error = true;
            }else if (LicenceType === 3 && Length >= 20) {
                error = true;
            }
        }
    }
    const handleDialogClose = () => {
        setDialog(false)
    }
    
    return( <div>{error ? <Dialog open={dialog} onClose={()=>{setDialog(false)}}>
        <DialogContent>
            <Alert severity="error">Your current plan doesn't have the ability to access this page upgrade to access.</Alert>
            <div className="flex justify-center mt-3">
                {/* <Button><TryFree CloseResp={handleDialogClose}/>Free</Button> */}
                <Button onClick={()=>{navigate('/Dashboard/settings');setDialog(false)}}>Upgrade</Button>
                <Button onClick={()=>{navigate('/Dashboard')}}>Go to Dashboard</Button>
            </div>
        </DialogContent>
    </Dialog>  : <Outlet/>}</div>);
};
export default ProtectPlanRoutes;