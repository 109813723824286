import { Alert, Button, CircularProgress, Dialog, DialogContent, DialogTitle } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import TryFree from "./TryFree";
import axiosInstance from "../../axios";

const UpgradingDialog = ({open, onClose, message}) => {
    const navigate = useNavigate();
    const [resp, setResp] = useState(false);
    const organization_id = localStorage.getItem('organization')
    const email = localStorage.getItem('email');
    const activatedTrial = localStorage.getItem('activatedTrial');


    // const navigate = useNavigate();
    const UpdateLicense = async () => {
        setResp('progress');
        try {
            const response = await axiosInstance.post(`${process.env.REACT_APP_ENDPOINT}/organization/${organization_id}/updateLicense?transactionRef=Free&email=${email}&amount=0&license_type=3&bill_type=monthly`);
            setResp('updated');
        } catch (error) {
            //console.log('error in updating', error)
            setResp('error');
        }
    };
    const closeDialog = () => {
        if (resp === 'updated') {
            localStorage.setItem('plan', 3);
            setResp(false);
            // CloseResp();
            window.location.reload();

        } else if (resp === 'EmailNotActive'|| resp === 'NoTrial') {
            setResp(false); 
            // CloseResp();
            navigate('/Dashboard/settings');
            
        } else { 
            setResp(false);
            //  CloseResp();
            }
    }
    // create date of today one month plus
    const addOneMonth = () => {
        const currentDate = new Date();
        const nextMonth = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
        // Subtract one day from the new date
        nextMonth.setDate(currentDate.getDate() - 1);
        // Format the date as DD/MM/YYYY
        const day = String(nextMonth.getDate()).padStart(2, '0');
        const month = String(nextMonth.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const year = nextMonth.getFullYear();

        return `${day}/${month}/${year}`;
    }
    const HandleValidation = () =>{
        let error = false;
        const emailActive = localStorage.getItem('emailActive');
        if (!emailActive) {
            error = true;
            setResp('EmailNotActive')
        }else if (activatedTrial) {
            error = true;
            setResp('NoTrial')
        }
        if (!error) {
            UpdateLicense();
        }
    }
    return(
        <div>
            <Dialog open={open} onClose={onClose}>
                <DialogContent>
                    <Alert severity="error">{message}</Alert>
                    <div className="flex justify-center mt-3">
                        {/* {!activatedTrial && (
                            <Button onClick={()=>{onClose();HandleValidation()}}
                            > Try free
                            </Button>
                        )} */}
                        <Button onClick={() => { navigate('/Dashboard/settings'); onClose(); }}>Upgrade</Button>
                        <Button onClick={() =>{onClose();}}>Okay</Button>
                    </div>
                </DialogContent>
            </Dialog>
            {/* Try for free one month prmium dialog */}
            <Dialog open={resp} onClose={closeDialog}>
                <DialogContent>
                    {resp === 'progress' ? (<div className="flex flex-col items-center justify-center w-full text-center">
                        <DialogTitle>Upgrading your license... please wait</DialogTitle>
                        <CircularProgress className="text-blue-800" />
                    </div>) : (<div><Alert severity={resp === 'updated' ? 'success' : 'error'}>
                        {resp === 'updated' ? `Congaratulations 🎉, You now have the premium plan for a month your free trial ends by ⌚
            ${addOneMonth()}.` : resp === 'EmailNotActive' ? 'Activate your email to use free one month premium plan.' : resp === 'NoTrial' ? 'You already used one month free premium, upgrade to continue using Gamizign the Ultimate AI Gamification' : 'An error occured please try again later.'}</Alert></div>)}
                </DialogContent>
                {resp !== 'progress' && <div className="flex justify-center pb-3">
                    <Button onClick={()=>{closeDialog()}}>{resp==='updated'? 'Game ON' : resp==='error'? 'Okay' : resp === 'NoTrial' ? 'Upgrade Now' : 'Activate my email now'}</Button>
                </div>}
            </Dialog>
        </div>
    );
};

export default UpgradingDialog;