import React, { useEffect, useState } from 'react';
import './style.css';
import IconGamizignWorld from '../../../assets/icons/AdminDashboard/DashboardIconOff.svg'
import IconGamizignWorldOn from '../../../assets/icons/AdminDashboard/DashboardIconOn.svg'
// Normal black State Sidebar Icons 
import IconNewActivity from '../../../assets/icons/AdminDashboard/AddIcon.svg'
import IconNewActivityOn from '../../../assets/icons/AdminDashboard/AddIconOn.svg'
import IconQuestionList from '../../../assets/icons/AdminDashboard/QuestionListIconOff.svg'
import IconQuestionListOn from '../../../assets/icons/AdminDashboard/QuestionListIconOn.svg'
import IconGamizign from '../../../assets/icons/AdminDashboard/GamizignIconOff.svg'
import IconGamizignOn from '../../../assets/icons/AdminDashboard/GamizignIconOn.svg'
import IconInsights from '../../../assets/icons/AdminDashboard/InsightsIconOff.svg'
import IconInsightsOn from '../../../assets/icons/AdminDashboard/InsightsIconOn.svg'
// import IconInstructor from '../../../assets/icons/left panel icons/NormalState/Instructors icon off.png'
// import IconInstructorOn from '../../../assets/icons/left panel icons/HoverState/Instructors icon on .png'
import IconLearnerList from '../../../assets/icons/AdminDashboard/LearnerListsIconOff.svg'
import IconLearnerListOn from '../../../assets/icons/AdminDashboard/LearnerListsIconOn.svg'
// import IconUsers from '../../../assets/icons/left panel icons/NormalState/Users icon off.png'
// import IconUsersOn from '../../../assets/icons/left panel icons/HoverState/Users icon on.png'
// import IconRewards from '../../../assets/icons/left panel icons/NormalState/Rewards off icon.png'
// import IconRewardsOn from '../../../assets/icons/left panel icons/HoverState/Rewards icon on.png'
import IconSettings from '../../../assets/icons/AdminDashboard/SettingsIconOff.svg'
import IconSettingsOn from '../../../assets/icons/AdminDashboard/SettingsIconOn.svg'
// import IconHelp from '../../../assets/icons/left panel icons/NormalState/Help icon off.png'
// import IconHelpOn from '../../../assets/icons/left panel icons/HoverState/Help icon on.png'
import LogoPic1 from '../../../assets/icons/AdminDashboard/GMZ logo icon 1.svg'
import LogoPic from '../../../assets/images/Gamizign-logo.png'
import { Link, Outlet, useLocation } from 'react-router-dom';
import { Box, Divider, Drawer, Hidden, IconButton, List, ListItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import OnboardingComponent from './sidbarOptionsContent/The Gamizign World/Components/Onboarding';
// import LoginHeading from '../../LoginHeading/LoginHeading';
import Googlelay from '../../../assets/images/Google-Play.png'
import AppleStore from '../../../assets/images/Apple-store.png'
import axiosInstance from '../../../axios';
import UpgradeIcon from "../../../assets/icons/UpgradeIcon.svg"
import UpgradingDialog from '../../GlobalComponents/UpgradingDialog';
import sidebarfull from '../../../assets/icons/SVGIcons/sidebarFull.svg'
import sidebarless from '../../../assets/icons/SVGIcons/sidebarless.svg'
import MenuIcon from '@mui/icons-material/Menu';




const Dashboard = ({mode, drawerWidth, setDrawerWidth}) => {
  const Tab = parseInt(localStorage.getItem("tab"));
  const [selectedTab, setSelectedTab] = useState(Tab);
  const [selectedTabName, setSelectedTabName] = useState('The Gamizign World');
  const LearnersList = localStorage.getItem("LearnersList");
  const LicenceType = parseInt(localStorage.getItem("plan"));
  const [Onboarding, setOnboarding] = useState(parseInt(localStorage.getItem("accountActive")));
  const defaultUrl = useLocation().pathname
   
  const menuItems = [
    {tooltip:LicenceType===3? '' :'Upgrade to enable', id: 11, icon: IconGamizignWorld, iconOn: IconGamizignWorldOn, label: 'The Gamizign World', style: 'BgcolorOf4 font-bold text-xl', link: LicenceType===3?  '/Dashboard':defaultUrl },
    { id: 1, icon: IconNewActivity, iconOn: IconNewActivityOn, label: 'New Activity', style: 'BgcolorOf4', link: '/Dashboard/CreateCourse' },
    { id: 2, icon: IconQuestionList, iconOn: IconQuestionListOn, label: 'Question Lists', style: 'BgcolorOf4', link: '/Dashboard/questionList' },
    { id: 3, icon: IconGamizign, iconOn: IconGamizignOn, label: 'Gamizign', style: 'BgcolorOf4', link: '/Dashboard/Courses' },
    { id: 4, icon: IconInsights, iconOn: IconInsightsOn, label: 'Insights', style: '', link: '/Dashboard/insights' },
    // { id: 5, icon: IconInstructor, iconOn: IconInstructorOn, label: 'Instructors', style: '', link: '/Dashboard/instructors' },
    { id: 6, icon: IconLearnerList, iconOn: IconLearnerListOn, label: 'Learner List', style: '', link: '/Dashboard/learnerList' },
    // { id: 6, icon: IconLearnerList, iconOn: IconLearnerListOn, label: 'Students/Classes', style: '', link: '/AddStudents' },
    // { id: 7, icon: IconUsers, iconOn: IconUsersOn, label: 'Users', style: '', link: '/Dashboard/users' },
    // { id: 8, icon: IconRewards, iconOn: IconRewardsOn, label: 'Rewards', style: '', link: '/Dashboard/rewards' },
    { id: 9, icon: IconSettings, iconOn: IconSettingsOn, label: 'Settings', style: '', link: '/Dashboard/settings' },
    // { id: 10, icon: IconHelp, iconOn: IconHelpOn, label: 'Help', style: '', link: '/Dashboard/help' },
  ];

  const [isNavOpen, setIsNavOpen] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleTabClick = (tabId, tabLabel) => {
    if (LicenceType === 3) {   
    setSelectedTab(tabId);
     localStorage.setItem("tab", tabId);
  }else if (tabId !== 11){
    setSelectedTab(tabId);
     localStorage.setItem("tab", tabId);
  }
  if (LicenceType !==3 && tabId === 11) {
    setErrorMessage("Your plan doesn't have access to use premium dashboard upgrade your plan to use it.")
  }
  if (!mobileOpen && window.innerWidth<1024) {
    setDrawerWidth(60);
  }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // const [drawerWidth, setDrawerWidth]= useState(240);
//   const [drawerWidth, setDrawerWidth] = useState(window.innerWidth > 1024 ? 240 : 60);

// useEffect(() => {
//   const handleResize = () => {
//     setDrawerWidth(window.innerWidth > 1024 ? 240 : 60);
//   }; 

//   window.addEventListener("resize", handleResize);
//   return () => window.removeEventListener("resize", handleResize);
// }, []);
  const drawer = (
    <div className='bg-[#DDE5F0]'>
      {/* {mobileOpen &&  */}
      {/* {(drawerWidth < 240 || mobileOpen) &&  */}
      <Box
      component="div"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 50,
        backgroundColor: '#6788A1',
        color: 'white',
        fontSize: 20,
      }}
      >
        <img src={LogoPic1} alt="Logo" style={{ height: '100%' }} />
      </Box>
      {/* } */}
      <Divider />
      {/* ${item.id === 11 && LicenceType !== 3 ? 'hidden' : ''} */}
      <List sx={{ padding: '0px' }}>
        {menuItems.map((item, index) => (
          <Tooltip arrow title={item.tooltip}>
          <Link className={`${item.id === 6 && LearnersList === 'hide' ? 'hidden' : '' } 

          `}
          id={`step-${index +1}`} to={item.link}>
            <ListItem 
              // button
              className={`${selectedTab === item.id ? 'bg-[#6788A1] text-white' : 'hover:bg-gray-200'}`}
              key={item.text}
              selected={selectedTab === item.id}
              onClick={(event) => { handleTabClick(item.id, event, index); setMobileOpen(false) }}
            >
              <ListItemIcon
              >
                <img src={(mode !== 'light'|| selectedTab === item.id) ?  item.iconOn : item.icon } alt="" />
              </ListItemIcon>
              {drawerWidth === 240 &&<ListItemText primary={item.label} />}
              {LicenceType !== 3 && item.id === 11 && <img src={UpgradeIcon} alt="" />}
            </ListItem>
            {/* <Divider /> */}
            </Link></Tooltip>
        ))}
      </List>
      <Divider />
      <div className={`flex ${drawerWidth === 60 ? 'justify-center' : 'justify-end'} w-full my-2`}>
        <img onClick={() => { setDrawerWidth(drawerWidth === 60 ? 240 : 60) }} className='cursor-pointer' src={drawerWidth === 60 ? sidebarfull : sidebarless} alt="toglesidebar" />
      </div>
    </div>

  );
const [errorMessage, setErrorMessage] = useState(false);
const closeDialog = () => {
  setErrorMessage(false);
}

  return (
    <div>
      <div className="flex">
      {/* {LearnersList === 'show' && !loading && classes[0].students.length === 1 && <OnboardingComponent/>} */}
      <UpgradingDialog open={errorMessage} onClose={closeDialog} message={errorMessage}/>
        {/* Sidebar Tabs*/}
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { xs: 0 } }}
          // sx={{ width: drawerWidth, flexShrink: { lg: 0 } }}
          // aria-label="mailbox folders"
        >
          <Hidden smUp implementation="css">
            <Drawer
              labelStyle={{fontFamily: 'Cairo_Regular'}}
              variant="temporary"
              // variant={drawerWidth < 240 ? 'permanent' : 'temporary'}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: 'block', sm: 'none' },
                transition: 'width 0.3s ease-in-out',
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: '#E8EFF9', overflowX:'hidden', transition: 'width 0.3s ease-in-out, transform 0.3s ease-in-out', },
              }}
            >
              {drawer}
              <div className={`absolute bottom-0 left-0 w-full`}>
              {/* <div className='flex justify-end w-full mt-2'>
                <img onClick={() => { setDrawerWidth(drawerWidth === 60 ? 240 : 60) }} className='cursor-pointer' src={drawerWidth === 60 ? sidebarfull : sidebarless} alt="toglesidebar" />
              </div> */}
                <Box
                  component="div"
                  sx={{
                    display:drawerWidth === 60 ? 'none' : 'block',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginLeft: '10px'
                  }}
                >
                  {/* {!mobileOpen && <img src={LogoPic} alt="Logo" className='w-20 h-20' />} */}
                  <div className='flex my-2 justify-center sm:justify-between'>
                    {!mobileOpen && <img src={LogoPic} alt="Logo" className='w-20 h-20' />}
                    <div className='me-5'>
                      <div><Link target="_blank" to='http://bit.ly/3Tw8PDV'><img className='' src={Googlelay} alt="" /></Link></div>
                      <div><Link target="_blank" to='https://apple.co/49Pc7I6'><img className='' src={AppleStore} alt="" /></Link></div>
                    </div>
                  </div>
                  <Link to='https://gamizign.com/privacy-policy/'><p className={`font-bold ${mobileOpen && 'mt-5'}`}>Privacy Policy & Terms of Use</p></Link>
                  <p>Subscription Agreement</p>
                </Box>
              </div>
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              variant="permanent"
              sx={{
                display: { xs: 'none', sm: 'block' },
                // display: 'block',
                transition: 'width 0.25s ease-in-out',
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: '#E8EFF9', overflowX:'hidden', transition: 'width 0.25s ease-in-out',},
              }}
              // open
              open={!mobileOpen}
            >
              {drawer}
              <div className={`absolute bottom-0 left-0 w-full`}>
              {/* <div className='flex justify-end w-full mb-2'>
                <img onClick={() => { setDrawerWidth(drawerWidth === 60 ? 240 : 60) }} className='cursor-pointer' src={drawerWidth === 60 ? sidebarfull : sidebarless} alt="toglesidebar" />
              </div> */}
                <Box
                  component="div"
                  sx={{
                    display:drawerWidth === 60 ? 'none' : 'block',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginLeft: '10px'
                  }}
                >
                  {/* {!mobileOpen && <img src={LogoPic} alt="Logo" className='w-20 h-20' />} */}
                  <div className='flex my-2 justify-center sm:justify-between'>
                    {!mobileOpen && <img src={LogoPic} alt="Logo" className='w-20 h-20' />}
                    <div className='me-5'>
                      <div><Link target="_blank" to='http://bit.ly/3Tw8PDV'><img className='' src={Googlelay} alt="" /></Link></div>
                      <div><Link target="_blank" to='https://apple.co/49Pc7I6'><img className='' src={AppleStore} alt="" /></Link></div>
                    </div>
                  </div>
                  <Link to='https://gamizign.com/privacy-policy/'><p className={`font-bold ${mobileOpen && 'mt-5'}`}>Privacy Policy & Terms of Use</p></Link>
                  <p>Subscription Agreement</p>
                </Box>
              </div>
            </Drawer>
          </Hidden>
        </Box>
        <div className='sm:hidden z-10 absolute top-0 left-0 px-3 py-[5px]' onClick={handleDrawerToggle}>
          <IconButton
            color="text-[#FFFFFF]"
            aria-label="open drawer"
            edge="start"
          >
            <MenuIcon className='text-[#FFFFFF]' />
          </IconButton>
        </div>

        <div className='MAINMENU flex-1'>
          <Outlet></Outlet>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;