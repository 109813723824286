import React from "react";
import {Navigate, Outlet  } from "react-router-dom";
import TrackPageView from "../../TrackPages";

const AdminRouteProtection = () =>{
    const admin_id = localStorage.getItem("admin_id");
    const token = sessionStorage.getItem('token');
    return(
        
        admin_id && token?
                <>
                <TrackPageView />
                <Outlet/>
                </>
                :
                <Navigate to='/'/>

    )
};
export default AdminRouteProtection;