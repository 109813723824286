import './App.css';
import './index.css';
import Dashboard from './components/pages/sidebar/sidebarMenu';
import SignInPage from './components/pages/signin/signin';
import { RouterProvider, createBrowserRouter, useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material/styles'; 
import RouteProtection from './components/RouteProtection/RouteProtection';
import AdminRouteProtection from './components/RouteProtection/AdminRouteProtection';
import { HelmetProvider } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { clarity } from 'react-microsoft-clarity';
import ProtectPlanRoutes from './components/RouteProtection/PlanProtectRoute';
import { lazy, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import LoadingComponent from './components/GlobalComponents/GlobalLoading';
import EditQuestionListSkeleton from './components/pages/sidebar/sidbarOptionsContent/QuestionList/components/EditQuestionListSkeleton';
import CoursesSkeleton from './components/pages/sidebar/sidbarOptionsContent/Gamizign/Components/CoursesSkeleton';
import PaymentRouteProtection from './components/RouteProtection/PaymentRouteProtection';
import BrowserUpdate from './browserUpdate';
import { initGA } from "./analytics"
import TrackPageView from './TrackPages';
// 
const NewCourse = lazy(()=>import('./components/pages/sidebar/sidbarOptionsContent/Activity/Newcourse/newCourse'));
const NewAiCourse = lazy(()=>import('./components/pages/sidebar/sidbarOptionsContent/Activity/Newcourse/newAiCourse'));
const Activity = lazy(()=>import('./components/pages/sidebar/sidbarOptionsContent/Activity/activity'));
const Adding = lazy(()=>import('./components/pages/sidebar/sidbarOptionsContent/Activity/Newcourse/adding'));
const QuestionList = lazy(()=>import('./components/pages/sidebar/sidbarOptionsContent/QuestionList/questionList'));
const Gamizign = lazy(()=>import('./components/pages/sidebar/sidbarOptionsContent/Gamizign/gamizign'));
const Insights = lazy(()=>import('./components/pages/sidebar/sidbarOptionsContent/Insights/insights'));
const LearnerList = lazy(()=>import('./components/pages/sidebar/sidbarOptionsContent/LearnerList/learnerList'));
const Settings = lazy(()=>import('./components/pages/sidebar/sidbarOptionsContent/Settings/settings'));
const CreatingPptx = lazy(()=>import('./components/pages/sidebar/sidbarOptionsContent/Activity/Newcourse/CreatingPptx'));
const CreatingPdf = lazy(()=>import('./components/pages/sidebar/sidbarOptionsContent/Activity/Newcourse/CreatingPdf'));
const Customizing = lazy(()=>import('./components/pages/sidebar/sidbarOptionsContent/Activity/Newcourse/customizing'));
const SavingQuestions = lazy(()=>import('./components/pages/sidebar/sidbarOptionsContent/Activity/Newcourse/Saving/savingQuestion'));
const ChooseGame = lazy(()=>import('./components/pages/sidebar/sidbarOptionsContent/Activity/Newcourse/Saving/chooseGame'));
const GameDone = lazy(()=>import('./components/pages/sidebar/sidbarOptionsContent/Activity/Newcourse/Saving/gameDone'));
// Patch 3
const AddingActivityType = lazy(()=>import('./components/pages/sidebar/sidbarOptionsContent/Activity/JourneyActivity/activityType'));
const MultiActivy = lazy(()=>import('./components/pages/sidebar/sidbarOptionsContent/Activity/JourneyActivity/MultiActivity/mutiActivity'));
const QuestionListEdit = lazy(()=>import('./components/pages/sidebar/sidbarOptionsContent/QuestionList/components/EditQuestionList'));
const EditReferencedQList = lazy(()=>import('./components/pages/sidebar/sidbarOptionsContent/QuestionList/editReferencedQList'));
const PdfDownloadQuestionList = lazy(()=>import('./components/pages/sidebar/sidbarOptionsContent/QuestionList/components/PdfDownloadQuestionList'));
const CourseBar = lazy(()=>import('./components/pages/sidebar/sidbarOptionsContent/Gamizign/Components/CourseBar'));
const CourseAnalytics = lazy(()=>import('./components/pages/sidebar/sidbarOptionsContent/Gamizign/Components/Analytics'));
const HomeWork = lazy(()=>import('./components/pages/sidebar/sidbarOptionsContent/Gamizign/Components/HomeWork/HomeWork'));
const ViewCourse = lazy(()=>import('./components/pages/sidebar/sidbarOptionsContent/Gamizign/Components/ViewCourse'));
const GamizignWorld = lazy(()=>import('./components/pages/sidebar/sidbarOptionsContent/The Gamizign World/gamizignWorld'));
const ActivityEvaluation = lazy(()=>import('./components/pages/sidebar/sidbarOptionsContent/Gamizign/Components/HomeWork/ActivityEva'));
const LearnerEva = lazy(()=>import('./components/pages/sidebar/sidbarOptionsContent/Gamizign/Components/HomeWork/LearnerEva'));
const Admin = lazy(()=>import('./components/Admin/Admin'));
const AllStudentsPerformance = lazy(()=>import('./components/Admin/AllStudents'));
const AllInstructorsPerformance = lazy(()=>import('./components/Admin/AllInstructors'));
const ViewQuestionsList = lazy(()=>import('./components/Admin/Questionlist/ViewQlist'));
//
const SignUp = lazy(()=>import('./components/pages/SignUp/Signup')); 
const ForgotPassword = lazy(()=>import('./components/pages/signin/ForgotPassword')); 
const PaymentForm = lazy(()=>import('./components/payment')); 
const UpgradePlan = lazy(()=>import('./components/RepayUpgrade/upgardePlan')); 
const CancelSubscription = lazy(()=>import('./components/pages/sidebar/sidbarOptionsContent/Settings/components/CancelSubscription')); 
const Players = lazy(()=>import('./components/players')); 

const newAiCourse = lazy(() => import ('./components/pages/sidebar/sidbarOptionsContent/Activity/Newcourse/newAiCourse'));
function App() {
  const LicenceType = parseInt(localStorage.getItem("plan"));
  const LearnersList = localStorage.getItem("LearnersList");
  // const navigate = useNavigate();
  const getInitialElement = () => {
    const user_type = localStorage.getItem("user_type");
    const id = localStorage.getItem("instructor_id");
    const token = sessionStorage.getItem('token');
    return( token && user_type === 'Instructor' ? <div className='flex'><Dashboard drawerWidth={drawerWidth} setDrawerWidth={setDrawerWidth} setMode={setMode} mode={mode} />{LicenceType === 3 ?<GamizignWorld setMode={setMode} mode={mode} /> :<Insights setMode={setMode} mode={mode}/> }</div>
       : token && user_type === 'Admin' ? <Admin drawerWidth={drawerWidth} setDrawerWidth={setDrawerWidth} setMode={setMode} mode={mode} /> :<SignInPage setMode={setMode} mode={mode}  />);
    // return id === null ? <SignInPage /> : <Dashboard />;
  };
  // For Sidebar scren adjustment
  const [drawerWidth, setDrawerWidth] = useState(window.innerWidth > 1024 ? 240 : 60);
  useEffect(() => {
    const handleResize = () => {
      setDrawerWidth(window.innerWidth > 1024 ? 240 : 60);
    }; 
  
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
    // Init Google analytics
    useEffect(() => {
      initGA();
    }, []);
  
  const [mode, setMode] = useState('light');
  const theme = mode === 'light' ? createTheme({
    typography: {
      allVariants: {
        fontFamily: 'Calibri',
        textTransform: 'none',
        fontSize: 16,
      },
    },
  }):createTheme({
    typography: {
      allVariants: {
        fontFamily: 'Calibri',
        textTransform: 'none',
        fontSize: 16,
      },
    },
    palette: {
      mode: 'dark',
      primary: {
        main: '#ff5252',
      },
    },
  });
  
  let router = createBrowserRouter([
    {
      path: "/",
      element: getInitialElement()
    },
    {
      path: "/signup",
      element: <ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><SignUp/></Suspense></ErrorBoundary>
    },
    {
      path: "/reset-password",
      element:<ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><ForgotPassword/></Suspense></ErrorBoundary>
    },
    {
      path:"/",
      element:<PaymentRouteProtection />,
      children:[
        {
          path:"/payment",
          element:<ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><PaymentForm/></Suspense></ErrorBoundary>,
        },
        {
          path:"/plans",
          element:<ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><CancelSubscription/></Suspense></ErrorBoundary>,
        },
        {
          path:"/upgradeplan",
          element:<ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><UpgradePlan/></Suspense></ErrorBoundary>,
        },
      ]
    },
    {
      path: "/Dashboard",
      element: <RouteProtection />,
      children: [
        {
          path: "/Dashboard/",
          element: <Dashboard drawerWidth={drawerWidth} setDrawerWidth={setDrawerWidth} setMode={setMode} mode={mode}/>,
          children: [
            {path: "/Dashboard/", element:<ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}>{LicenceType === 3 ?<GamizignWorld setMode={setMode} mode={mode} /> : <Insights setMode={setMode} mode={mode} />}</Suspense></ErrorBoundary>},
            {path: "/Dashboard/CreateCourse", element:<ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><Activity setMode={setMode} mode={mode} /></Suspense></ErrorBoundary>},
            // Tabs routes here
            {path: "/Dashboard/questionList", element:<ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><QuestionList setMode={setMode} mode={mode} /></Suspense></ErrorBoundary>},
            {path: "/Dashboard/refQuestionList", element:<ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><EditReferencedQList setMode={setMode} mode={mode} /></Suspense></ErrorBoundary>},
            {path: "/Dashboard/Courses", element: <ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<CoursesSkeleton/>}><Gamizign setMode={setMode} mode={mode} /></Suspense></ErrorBoundary>},
            {path: "/Dashboard/insights", element:<ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><Insights setMode={setMode} mode={mode} /></Suspense></ErrorBoundary>},
            // {path: "/Dashboard/instructors", element: <Instructors />},
            {path: "/Dashboard/learnerList", element: LearnersList === 'show' ? <ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><LearnerList /></Suspense></ErrorBoundary> : <></>},
            // {path: "/Dashboard/users", element: <Users />},
            // {path: "/Dashboard/rewards", element: <Rewards />},
            {path: "/Dashboard/settings", element:<ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><Settings /></Suspense></ErrorBoundary>},
            // {path: "/Dashboard/help", element: <Help />},
            // Create New Activity 
            // {path: "/Dashboard/newcourse", element: <NewCourse setMode={setMode} mode={mode} />},
            {path: "/Dashboard/newcourse", element: <ProtectPlanRoutes/>, children:[{path: "/Dashboard/newcourse", element:<ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><NewCourse setMode={setMode} mode={mode} /></Suspense></ErrorBoundary>}]},
            {path: "/Dashboard/newAiCourse", element: <ProtectPlanRoutes/>, children:[{path: "/Dashboard/newAiCourse", element:<ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><NewAiCourse setMode={setMode} mode={mode} /></Suspense></ErrorBoundary>}]},
            {path: "/Dashboard/adding", element: <ProtectPlanRoutes/>, children:[{path: "/Dashboard/adding", element:<ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><Adding setMode={setMode} mode={mode} /></Suspense></ErrorBoundary>}]},
            {path: "/Dashboard/creatingPptx", element: <ProtectPlanRoutes/>, children:[{path: "/Dashboard/creatingPptx", element:<ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><CreatingPptx setMode={setMode} mode={mode} /></Suspense></ErrorBoundary>}]},
            {path: "/Dashboard/creatingPdf", element: <ProtectPlanRoutes/>, children:[{path: "/Dashboard/creatingPdf", element:<ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><CreatingPdf setMode={setMode} mode={mode} /></Suspense></ErrorBoundary>}]},
            {path: "/Dashboard/customizing", element: <ProtectPlanRoutes/>, children:[{path: "/Dashboard/customizing", element:<ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><Customizing setMode={setMode} mode={mode} /></Suspense></ErrorBoundary>}]},
            {path: "/Dashboard/saveQuestions", element: <ProtectPlanRoutes/>, children:[{path: "/Dashboard/saveQuestions", element:<ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><SavingQuestions setMode={setMode} mode={mode} /></Suspense></ErrorBoundary>}]},
            {path: "/Dashboard/chooseGame", element: <ProtectPlanRoutes/>, children:[{path: "/Dashboard/chooseGame", element:<ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><ChooseGame setMode={setMode} mode={mode} /></Suspense></ErrorBoundary>}]},
            {path: "/Dashboard/gameDone", element: <ProtectPlanRoutes/>, children:[{path: "/Dashboard/gameDone", element:<ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><GameDone setMode={setMode} mode={mode} /></Suspense></ErrorBoundary>}]},
            // Patch 3 Journey course
            {path: "/Dashboard/activityType", element: <ProtectPlanRoutes/>, children:[{path: "/Dashboard/activityType", element:<ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><AddingActivityType setMode={setMode} mode={mode} /></Suspense></ErrorBoundary>}]},
            // Multi Activity Journey course
            {path: "/Dashboard/multiActivities", element: <ProtectPlanRoutes/>, children:[{path: "/Dashboard/multiActivities", element:<ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><MultiActivy setMode={setMode} mode={mode} /></Suspense></ErrorBoundary>}]},
            // Question List 
            {path: "/Dashboard/DownloadQuestionsList", element:<ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><PdfDownloadQuestionList setMode={setMode} mode={mode} /></Suspense></ErrorBoundary>},
            {path: "/Dashboard/EditQuestionsList", element: <ProtectPlanRoutes/>, children:[{path: "/Dashboard/EditQuestionsList", element:<ErrorBoundary FallbackComponent={EditQuestionListSkeleton}><Suspense fallback={<EditQuestionListSkeleton/>}><QuestionListEdit setMode={setMode} mode={mode} /></Suspense></ErrorBoundary>}]},    
          ]
        },
      ]},

    
    // Gamizign your courses
    {
      path: "/Course",
      element: <RouteProtection />, // Wrap the parent route with RouteProtection
      children: [
        {
          path: "/Course/",
          element: <ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><CourseBar drawerWidth={drawerWidth} setDrawerWidth={setDrawerWidth} setMode={setMode} mode={mode}/></Suspense></ErrorBoundary>,
          children: [
            {path: "/Course/", element: <ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><CourseAnalytics setMode={setMode} mode={mode} /></Suspense></ErrorBoundary>,},
            {path: "/Course/players", element: <ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><Players setMode={setMode} mode={mode} /></Suspense></ErrorBoundary>,},
            {path: "/Course/HomeWork", element: <ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><HomeWork setMode={setMode} mode={mode} /></Suspense></ErrorBoundary>,},
            // {path: "/Course/CourseSetting", element: <ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><CourseSetting setMode={setMode} mode={mode} /></Suspense></ErrorBoundary>,},
            {path: "/Course/ViewCourse", element: <ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><ViewCourse setMode={setMode} mode={mode} /></Suspense></ErrorBoundary>,},
            // {path: "/Course/PublishCourse", element: <ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><PublishCourse /></Suspense></ErrorBoundary>,},
            {path: "/Course/ActivityEvaluation", element: <ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><ActivityEvaluation setMode={setMode} mode={mode} /></Suspense></ErrorBoundary>,},
            {path: "/Course/LearnerEvaluation", element: <ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><LearnerEva setMode={setMode} mode={mode} /></Suspense></ErrorBoundary>,},
          ]
        },
      ]},
  
    // Admin Client routing
    {
      path: "/Admin",
      element:<AdminRouteProtection/>,
      children:[
        {
          path: "/Admin",
          element:<ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><Admin drawerWidth={drawerWidth} setDrawerWidth={setDrawerWidth} setMode={setMode} mode={mode}/></Suspense></ErrorBoundary>
        },
        {
          path: "/Admin/InstructorsPerformace",
          element:<ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><AllInstructorsPerformance setMode={setMode} mode={mode}/></Suspense></ErrorBoundary>
        },
        {
          path: "/Admin/StudnetsPerformace",
          element:<ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><AllStudentsPerformance setMode={setMode} mode={mode}/></Suspense></ErrorBoundary>
        },
        {
          path: "/Admin/ViewQuestions",
          element:<ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><ViewQuestionsList setMode={setMode} mode={mode}/></Suspense></ErrorBoundary>
        },
      ]
    },
    
  ])
  
  clarity.init('l2n6imfomk');

  return (
    
    <ThemeProvider theme={theme}>
      <div>
      <HelmetProvider>
        <RouterProvider router={router} />
        <BrowserUpdate />
        </HelmetProvider>
        {/* <SignInPage/>  */}
      </div>
    </ThemeProvider>
  );
}

export default App;
