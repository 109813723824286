import { Alert, Button, CircularProgress, Dialog, DialogContent, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../../../../axios";
import ChooseMainTopic from "../../../../../GlobalComponents/ChooseMainTopic";


const OnboardingComponent = () => {
    const navigate = useNavigate();
    const [IsDialog, setIsDialog] = useState(true);
    const [QuestionLists, setQuestionLists] = useState(null);
    const [MainTopic, setMainTopic] = useState('');
    const [QuestionList_id, setQuestionList_id] = useState('');
    const [mainTopicError, setMainTopicError] = useState('');
    const [QuestionList_idError, setQuestionList_idError] = useState('');
    const [progress, setIsProgress] = useState(false);
    const [savingError, setSavingError] = useState(false);
    const handleClose = () => {
        setIsDialog(false);
    };
    useEffect( ()=>{
        const getFuntuion = async () => {
            const geTQuestionlists = await axiosInstance.get(`${process.env.REACT_APP_ENDPOINT}/question-lists/qualified`);
            setQuestionLists(geTQuestionlists.data);            
        }
        getFuntuion();
    },[])
    const handleMainTopicChange = (e) => {
        setMainTopic(e.target.value);
        setMainTopicError('');
        setQuestionList_id('')
    }
    // Formmation of today's date
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
    let hh = today.getHours();
    let min = today.getMinutes();
    let ss = today.getSeconds();
    let mmF = today.getMonth() + 2; // Months start at 0!

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
    const formattedToday = dd + '/' + mm + '/' + yyyy;
    const finshingDate = dd + '/' + mmF + '/' + yyyy;
    var inst_name = localStorage.getItem("name");
    const HandleChoseGame = async () => {
        let error=false;
        if (!MainTopic) {
            error=true;
            setMainTopicError('Please selecte Maint Topic');
        }
        if (!QuestionList_id) {
            error=true;
            setQuestionList_idError('Please selecte question lits')
        }
        if (!error) {
                setSavingError(false);
                    setIsProgress(true);
                    const courseObject = {
                        name: `${inst_name}'s first course`,
                        courseType: "Normal",
                        background: "",
                        grade: classes[0].class_id,
                        mainTopic: MainTopic,
                        subTopics: '',
                        startingDate: formattedToday,
                        stages: [
                            {
                                name: `${inst_name}'s first stage `,
                                startingDate: formattedToday,
                                finishingDate: finshingDate,
                                activities: [],
                            }
                        ]
                }
                    const selectedQuestionList = QuestionLists.find(qList => qList.questionList_id === QuestionList_id);
                    try {
                        var instructor_id = localStorage.getItem("instructor_id");
                        var req = {
                            "name": selectedQuestionList.name,
                            "dateCreated": formattedToday,
                            "createdBy": inst_name,
                            "lastModified": formattedToday,
                            "mainTopic": MainTopic,
                            "questions": selectedQuestionList.questions,
                            "instructor_id": instructor_id
                        }
                        var activity = {
                            "name": `${inst_name}'s first activity `,
                            "activityType": "game",
                            "scoreVolume": 50,
                            "instructor_id": instructor_id,
                            "actionType": 0,
                            "dateCreated": formattedToday,
                            "createdBy": inst_name,
                        }
                        const response = await axiosInstance.post(`${process.env.REACT_APP_ENDPOINT}/instructors/${instructor_id}/questions/`, req);
        
                        if (response.data) {
                            const getQlists = await axiosInstance.get(`${process.env.REACT_APP_ENDPOINT}/instructors/${instructor_id}/question-lists`);
                            if (getQlists.data) {
                                localStorage.setItem("qestionListsLength", getQlists.data.length);
                                activity.questionList_id = response.data.questionList_id;
                                navigate('/Dashboard/chooseGame', { state: {courses:"", courseType: 'single', course: courseObject, activity: activity, questionList_id: response.data.questionList_id, stageIndex: "", courseFormatDate: "", stratingDate: "", finishingDate: "" , copyCourse:"GameOn" } });
                            }else{
                                setIsProgress(false);
                                setSavingError(true);
                            }
                        }
                    } catch (error) {
                        setIsProgress(false);
                        setSavingError(true);
                    }
        
            }
        }
    // get classes and manage WebGl flow
    const [Onboarding, setOnboarding] = useState(parseInt(localStorage.getItem("accountActive")));
    const LearnersList = localStorage.getItem("LearnersList");
    const [classes, setClasses] = useState(null);
    const [loading, setLoading] = useState(true);
    const students = parseInt(localStorage.getItem('studentsLenght'));
    const getClasses = async () => {
        setLoading(true);
        var org = localStorage.getItem("organization");
        const resp = await axiosInstance.get(`${process.env.REACT_APP_ENDPOINT}/organization/${org}/classes/`);
        setClasses(resp.data.classes);
        setLoading(false);
    };
    useEffect(() => {
        getClasses();
    }, []);
    
 
    return (
        <div>
            {LearnersList === 'show' && !loading && students <= 1 && Onboarding === 0 &&
            <div>
                <Dialog className="sm:ms-56" open={IsDialog} onClose={handleClose}>
                <DialogContent>
                    <div className="flex justify-center text-xl font-bold mb-3"><h1 className="w-40">Let's Game on</h1></div>
                <div className='mt-5 xl:mt-0'>
                {progress && (
                    <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-75 z-50">
                        <CircularProgress className="text-blue-800" />
                    </div>
                )}
                <ChooseMainTopic MainTopic={''} mainTopicError={mainTopicError} handleMainTopicChange={handleMainTopicChange} />
                                    {mainTopicError && <p className="text-red-500">{mainTopicError}</p>}
                                </div>
                                {/* Question lists */}
                                <FormControl margin="dense" fullWidth
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    border:'2px solid #F3F6F6',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    border: '2px solid #DBE6F5',
                                                },
                                                '&:hover fieldset': {
                                                    border: '2px solid #DBE6F5',
                                                },
                                            },
                                        }}>
                                        <InputLabel>Questions list</InputLabel>
                                        <Select
                                            labelId="Questions list"
                                            name="Questions list"
                                            label="Questions list"
                                            value={QuestionList_id}
                                            onChange={(e) => {setQuestionList_id(e.target.value); setQuestionList_idError('')}}
                                            style={{ borderRadius: '10px', color: '#2E4EB5', background: '#F3F6F6'}}
                                        >
                                            {QuestionLists && QuestionLists.length > 0 ? QuestionLists?.map((questionsList, index) => (
                                                <MenuItem sx={{ display: `${questionsList.mainTopic == MainTopic ? '' : 'none'}` }}
                                                // <MenuItem
                                                key={questionsList.questionList_id} value={questionsList.questionList_id}>
                                                    {questionsList.name}
                                                </MenuItem>
                                            )):<div className="flex justify-center"><CircularProgress className="text-blue-800" /></div>}
                                        </Select>
                                    </FormControl>
                                    {QuestionList_idError && <p className="text-red-500">{QuestionList_idError}</p>}
                </DialogContent>
                <div className="flex justify-center">
                    <Button onClick={HandleChoseGame}>Next</Button>
                    <Button onClick={handleClose}>Cancel</Button>
                </div>
            </Dialog>
            {/* error dialog */}
            <Dialog open={savingError} onClose={()=>setSavingError(false)}><DialogContent>
                    <Alert severity="error">An error occurred please try again later.</Alert>
                    <div className="flex justify-center">
                        <Button onClick={()=>setSavingError(false)}>Okay</Button>
                    </div>
                    </DialogContent></Dialog>
            </div>}
            </div>
    )
};
export default OnboardingComponent;