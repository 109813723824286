import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { logPageView } from "./analytics";

const TrackPageView = () => {
    const location = useLocation(); // Get current route
    useEffect(() => {
      logPageView(); // Make sure this function is defined
    }, [location]);
  
    return null; // No UI needed
  };
export default TrackPageView;