import React, { useState, useEffect, useRef } from "react";
import './style.css'
import GamizignLogo from '../../../assets/images/SigninPageImages/Gamizign-logo.png'
import GMZbg from '../../../assets/images/SigninPageImages/BG_for_Login_12-10-2024.png'
import upgradIcon from '../../../assets/icons/SVGIcons/AIGenerateIcon.svg'
import HeartIcon from '../../../assets/icons/SVGIcons/Heart 16-16 1.svg'
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import CircularProgress from '@mui/material/CircularProgress';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Alert, Dialog, DialogContent, InputAdornment } from "@mui/material";
import AccountCircle from '@mui/icons-material/AccountCircle';
import KeyIcon from '@mui/icons-material/Key';
import EmailIcon from '@mui/icons-material/Email';
import axios from "axios";
import { logEvent } from "../../../analytics";


const SignIn = ({ mode, setMode}) => {
    const logoRef = useRef(null);
    const [showLoginFields, setShowLoginFields] = useState(false);

    function HorizontalNonLinearStepper() {
        const [activeStep, setActiveStep] = React.useState(0);
        const [completed, setCompleted] = React.useState({});
        const [OrgName, setOrgName] = useState('');
        const [OrgNameError, setOrgNameError] = useState('');
        const [FirstName, setFirstName] = useState('');
        const [FirstNameError, setFirstNameError] = useState('');
        const [LastName, setLastName] = useState('');
        const [LastNameError, setLastNameError] = useState('');
        const [Email, setEmail] = useState('');
        const [EmailError, setEmailError] = useState('');
        const [Password, setPassword] = useState('');
        const [PasswordError, setPasswordError] = useState('');
        const [Progrees, setProgrees] = useState(false);

        // create admin and org request
        const CreateIncHandler = async () => {
          logEvent('signup','signup clicked',`Signup individual instrctor with ${license_type === 0 ? 
            'Free':license_type === 1?'Basic':license_type === 2?'Standard':'Premium'} plan.`);
          setProgrees(true);
          try {
           const  Verifyemail = await axios.post(`${process.env.REACT_APP_ENDPOINT}/check-email?email=${Email}`);
            if (!Verifyemail.data) {
              const organizationResponse = await axios.post(`${process.env.REACT_APP_ENDPOINT}/organization/`, {
                name: `${FirstName}'s Organization`,
                workspaceType: `${FirstName}'s workSpace`, 
                license_type: license_type,
                bill_type : license_type === 0 ? '' : bill_Type,
              });
              const { organization_id} = organizationResponse.data;    
              if (organizationResponse.status === 200) {
                const CreateInc = await axios.post(`${process.env.REACT_APP_ENDPOINT}/instructors/`, {
                  firstName: FirstName ,
                  lastName: LastName ,
                  email:Email ,
                  organization_id: organization_id,
                  hashed_password : Password
                });
                if (CreateInc.status === 200) {
                  const NewClass ={
                    class_name: `${FirstName} Organization Class1`,
                    organization_id: organization_id,
                  } 
                    const reqBody = {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                      },
                      body:JSON.stringify(NewClass), 
                    } 
                    const CreateClass = await fetch(`${process.env.REACT_APP_ENDPOINT}/organizations/${organization_id}/classes/`, reqBody);
                        if (license_type === 0 || license_type === 3) {
                          if (license_type === 3) {
                            const updateUser = await fetch(`${process.env.REACT_APP_ENDPOINT}/organization/${organization_id}/updateLicense?transactionRef=Free&email=${CreateInc.data.email}&amount=0&license_type=3&bill_type=monthly`,{
                              method: 'POST',
                              headers: {
                                'Content-Type': 'application/json',
                                'Authorization':`Bearer ${CreateInc.data.access_token}`
                            }});
                            window.location.href='/Dashboard/CreateCourse'
                          }else{window.location.href='/Dashboard/CreateCourse'}
                          localStorage.setItem("instructor_id", CreateInc.data.instructor_id);
                          localStorage.setItem("name", CreateInc.data.firstName);
                          localStorage.setItem("lastname", CreateInc.data.lastName);
                          localStorage.setItem("email", CreateInc.data.email);
                          localStorage.setItem("organization", CreateInc.data.organization_id);
                          localStorage.setItem("user_type", 'Instructor');
                          sessionStorage.setItem("token", `Bearer ${CreateInc.data.access_token}`);
                          localStorage.setItem("LearnersList", 'show');
                          localStorage.setItem("accountActive", 0);
                          localStorage.setItem("plan", license_type);
                          localStorage.setItem("coursesLength", CreateInc.data.courses);
                          localStorage.setItem("qestionListsLength", CreateInc.data.questions);
                          localStorage.setItem("tab", 1);
                        }
                        else{
                            sessionStorage.setItem("token", `Bearer ${CreateInc.data.access_token}`);
                            navigate('/payment', {state: { license_type : license_type, bill_type : bill_Type, organization_id: CreateInc.data.organization_id} });
                          }
                  setProgrees(false);
                  setIsSignUp(false);
                  setPymentError(true);
                  setErrorMessage('Congratulations your account has been created, Go into email to activate your account.');
                }else{
                  setProgrees(false);
                  setPymentError(true);
                  setErrorMessage('An error occured while activiting your email please try again later.');
                }
          
              } else {
                console.error('Organization creation data failed');
                setProgrees(false);
                setPymentError(true);
                setErrorMessage('An error occured while activiting your email please try again later.');
              }
            }

          } catch (error) {
            console.error('Error occurred during the requests:', error);
            console.error('Error creating new Org.');
            setProgrees(false);
            setPymentError(true);
            if (error.response.data.detail === 'Email already registered') {
              setErrorMessage(`This '${Email}' email has been already taken please try with another email.`);
            }else{
            setErrorMessage('An error occured while creating your account please try again later.');
            }
          }
        };
        
        const handleSubmitAccount = (event) => {
          event.preventDefault();
          let error = false;
            // if (!OrgName) {
            //   error= true;
            //   setOrgNameError('Please fill the Organization name.')
            // }
            if (!FirstName) {
              error= true;
              setFirstNameError('Please enter you first name.')
            }
            // if (!LastName) {
            //   error= true;
            //   setLastNameError('Please fill your last name.')
            // }
            if (!Email) {
              error= true;
              setEmailError('Please enter your email.')
            }
            if (Email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(Email)) {
              setEmailError('Please fill the Email in correct format like `example@gamizign.com` ')
              error= true;

            }
            if (!Password) {
              error = true;
              setPasswordError('Please enter your password.')
            } else if (Password.length < 8) {
              error = true;
              setPasswordError('Password must be at least 8 characters long.');
            } else if (!/[a-zA-Z]/.test(Password)) {
              error = true;
              setPasswordError('Password must contain at least one alphabetic character.(a-zA-Z)');
            } else if (Password.length >= 8 && /[a-zA-Z]/.test(Password)) {
              setPasswordError('Perfect ✔');
            }

          if(!error){
            CreateIncHandler();
          }
        };
      // Validate password 8 chars and must one alphabetic char and make error live , show hide 
      const [showPassword, setShowPassword] = useState(false);
      const passwordHandler = (value) => {
        setPassword(value);
        if (!value) {
          setPasswordError('Please enter the Password.')
        } else if (value.length < 8) {
          setPasswordError('Password must be at least 8 characters long.');
        } else if (!/[a-zA-Z]/.test(value)) {
          setPasswordError('Password must contain at least one alphabetic character.(a-zA-Z)');
        } else if (value.length >= 8 && /[a-zA-Z]/.test(value)) {
          setPasswordError('Perfect ✔');
        }
      }
      
      
        return (
          <Box sx={{ width: '100%' }}>
            <div>
                <React.Fragment>
                  <div className="flex justify-center mt-3 px-5 max-w-xl">
                    <form onSubmit={handleSubmitAccount} className='drop-shadow-xl'>
                    {Progrees && (
                      <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-75 z-50">
                        <CircularProgress className="text-blue-800" />
                      </div>
                    )}
                    <div className="relative shadow-xl bg-white px-10 py-5 rounded-[10px]">
                      <h1 className="text-[#4183d9] text-3xl text-center font-bold">Experience Gamizign Premium Free for a Month!</h1>
                      <p>Unlock all premium features for a whole month, absolutely free! Explore Gamizign's powerful tools and discover how they can elevate your content. 
                      <b> No commitment required – cancel anytime.</b></p>
                      <img className="absolute -right-5 -top-9" src={upgradIcon} alt="upgradIcon" />
                    </div>
                      {/* <h1 className="flex justify-center text-xl">Organization info</h1> */}
                      {/* <TextField
                        value={OrgName}
                        autoFocus
                        placeholder="Enter Organization name"
                        autoComplete='off'
                        onChange={(e) => { { setOrgName(e.target.value); setOrgNameError('') } }}
                        margin="dense" id="Orgnization Name" label="Enter Orgnization Name" variant="outlined"
                        sx={{
                          width: '100%',
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: mode === 'light' ? 'none' : '',
                            },
                            '&.Mui-focused fieldset': {
                              border: '2px solid #4183d9',
                            },
                          },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <CorporateFareIcon style={{ color: mode === 'light' ? '#4183d9' : '' }} />
                            </InputAdornment>
                          ),
                          style: {
                            borderRadius: "10px",
                            fontSize: '20px',
                            color: mode === 'light' ? '#4183d9' : '', background: mode === 'light' ? 'white' : '',
                          }
                        }}
                      />
                      {OrgNameError && <p style={{ color: 'red' }}>{OrgNameError}</p>} */}
                      <div className='drop-shadow-xl mt-3'>
                        <h1 className="flex justify-center text-xl">Account info</h1>
                        <TextField
                          margin="dense"
                          label="Name"
                          name="firstName"
                          type="text"
                          autoComplete='off'
                          placeholder="Enter your first name"
                          fullWidth
                          sx={{
                            width: '100%',
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                border: mode === 'light' ? 'none' : '',
                              },
                              '&.Mui-focused fieldset': {
                                border: '2px solid #4183d9',
                              },
                            },
                          }}
                          value={FirstName}
                          onChange={(e) => { setFirstName(e.target.value); setFirstNameError('') }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AccountCircle style={{ color: mode === 'light' ? '#4183d9' : '' }} />
                              </InputAdornment>
                            ),
                            style: {
                              borderRadius: "10px",
                              fontSize: '20px',
                              color: mode === 'light' ? '#4183d9' : '', background: mode === 'light' ? 'white' : '',
                            }
                          }}
                        />
                        {FirstNameError && <p style={{ color: 'red' }}>{FirstNameError}</p>}
                        <div className="my-3">
                          {/* <TextField
                            margin="dense"
                            label="Last Name"
                            name="lastName"
                            type="text"
                            autoComplete='off'
                            placeholder="Enter your last name"
                            fullWidth
                            sx={{
                              width: '100%',
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  border: mode === 'light' ? 'none' : '',
                                },
                                '&.Mui-focused fieldset': {
                                  border: '2px solid #4183d9',
                                },
                              },
                            }}
                            value={LastName}
                            onChange={(e) => { setLastName(e.target.value); setLastNameError('') }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <AccountCircle style={{ color: mode === 'light' ? '#4183d9' : '' }} />
                                </InputAdornment>
                              ),
                              style: {
                                borderRadius: "10px",
                                fontSize: '20px',
                                color: mode === 'light' ? '#4183d9' : '', background: mode === 'light' ? 'white' : '',
                              }
                            }}
                          />
                          {LastNameError && <p style={{ color: 'red' }}>{LastNameError}</p>} */}
                        </div>
                        <TextField
                          margin="dense"
                          label="Email"
                          name="email"
                          type="email"
                          autoComplete='off'
                          placeholder="Enter email"
                          fullWidth
                          value={Email}
                          sx={{
                            width: '100%',
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                border: mode === 'light' ? 'none' : '',
                              },
                              '&.Mui-focused fieldset': {
                                border: '2px solid #4183d9',
                              },
                            },
                          }}
                          onChange={(e) => { setEmail(e.target.value); setEmailError('') }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <EmailIcon style={{ color: mode === 'light' ? '#4183d9' : '' }} />
                              </InputAdornment>
                            ),
                            style: {
                              borderRadius: "10px",
                              fontSize: '20px',
                              color: mode === 'light' ? '#4183d9' : '', background: mode === 'light' ? 'white' : '',
                            }
                          }}
                        />
                        {EmailError && <p style={{ color: 'red' }}>{EmailError}</p>}
                        <div className="mt-3 w-full">
                          <TextField
                            margin="dense"
                            label="Password"
                            name="hashed_password"
                            type={showPassword ? 'text' : 'password'}
                            fullWidth
                            placeholder="Enter password"
                            value={Password}
                            sx={{
                              // width:{sm:'30vw'},
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  border: mode === 'light' ? 'none' : '',
                                },
                                '&.Mui-focused fieldset': {
                                  border: '2px solid #4183d9',
                                },
                              },
                            }}
                            onChange={(e) => {passwordHandler(e.target.value) }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <KeyIcon style={{ color: mode === 'light' ? '#4183d9' : '' }} />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment sx={{cursor:'pointer'}} position="end">
                                   <span onClick={()=>setShowPassword(prev => !prev)} className="cursor-pointer">{showPassword ? <FaEye style={{color: mode === 'light' ? '#4183d9' : ''}} size={24}/> : <FaEyeSlash style={{color: mode === 'light' ? '#4183d9' : ''}} size={24}/>}</span>
                                </InputAdornment>
                            ),
                              style: {
                                borderRadius: "10px",
                                fontSize: '20px',
                                color: mode === 'light' ? '#4183d9' : '', background: mode === 'light' ? 'white' : '',
                              }
                            }}

                          />
                          {PasswordError && <p style={{color:PasswordError === 'Perfect ✔' ? 'green' : 'red'}}>{PasswordError}</p>}
                        </div>
                      </div>
                      <div className="flex justify-center">
                        <button type="submit" className="bg-cyan-600 hover:bg-orange-600 text-white font-bold rounded-[10px] shadow-xl px-10 py-3 mt-5"
                          // onClick={handleSubmit}
                        >
                          Start Your Free Trial Now!
                        </button>
                      </div>
                    </form>
                  </div>
                </React.Fragment>
            </div>
          </Box>
        );
      }
    // const history = useHistory(); // Use useHistory hook for navigation
    const { CreateAccount, license_type, bill_Type } = useLocation().state || false;
    const [isSignUp , setIsSignUp] = useState(CreateAccount)
    const [IncEmail , setIncEmail] = useState('')
    const navigate = useNavigate();
    const [state, setState] = useState({
        showPassword: false,
        password: '',
        error: '',
        isLoading: false,
    });
    // Navigate payment page for repay
    const [detalsData, setdetalsData] = useState(null)
    const repay = () => {
      navigate(`/upgradeplan`, {state: detalsData});
    }
    // Login handle
    const handleSubmit = async (event) => {
      logEvent('signin','login clicked','Login by enetering email and password');
      event.preventDefault();
      setState((prevState) => ({ ...prevState, error: '', isLoading: true }));
      handleLoginResult(true);
      const authData = {
        username: event.target.email.value,
        password: event.target.password.value
      };

      try {
          const response = await axios.post(`${process.env.REACT_APP_ENDPOINT}/token`, authData, {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/x-www-form-urlencoded',
              'Cache-Control': 'no-cache', 
            }
          });
          axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.access_token}`;
          const responseData = response.data;
          localStorage.setItem("user_type", responseData.user_type);
          sessionStorage.setItem("token", `Bearer ${responseData.access_token}`);
          if (responseData.user_type === "Instructor") { 
            const getCoursesCount = await axios.get(`${process.env.REACT_APP_ENDPOINT}/instructors/{instructor_id}/account/?email=${authData.username}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization':`Bearer ${responseData.access_token}`
              }});
            const {firstName, lastName, email, instructor_id, organization_id, emailActivated, accountActive, admin_id, courses_count, questions_count, student_count} = getCoursesCount.data; 
              const LearnerTab = admin_id ?  'hide' : 'show' ;
              const Onboarding = accountActive ?  2 : 0 ;
              localStorage.setItem("instructor_id", instructor_id);
              localStorage.setItem("admin_id", admin_id);
              localStorage.setItem("name", firstName);
              localStorage.setItem("lastname", lastName);
              localStorage.setItem("email", email);
              localStorage.setItem("organization", organization_id);
              localStorage.setItem("LearnersList", LearnerTab);
              localStorage.setItem("accountActive", Onboarding);
              localStorage.setItem("plan", responseData.license.license_type);
              localStorage.setItem("coursesLength", courses_count);
              localStorage.setItem("qestionListsLength", questions_count);
              localStorage.setItem("studentsLenght", student_count);
              localStorage.setItem("emailActive", emailActivated ? emailActivated : "");
              localStorage.setItem("activatedTrial", responseData.license.activatedTrial ? responseData.license.activatedTrial : "");
              localStorage.setItem("tab", responseData.license.license_type === '3' ? 11 : 4);
              console.log(responseData);
              console.log('test',getCoursesCount.data);
              if (LearnerTab === 'show' && Onboarding == 0) {
                localStorage.setItem("tab", 1);
                window.location.href='/Dashboard/CreateCourse'
              }else if(LearnerTab === 'hide'){
                if (questions_count<1) {
                  window.location.href = `/Dashboard/questionList`;
                  localStorage.setItem("tab", 2);
                }else if (courses_count<1) {
                  window.location.href = `/Dashboard/CreateCourse`;
                  localStorage.setItem("tab", 1);
                  
                }else{
                  window.location.href = `/Dashboard`;
                  localStorage.setItem("tab", 11);
                }
              }
          } else {
              localStorage.setItem("admin_id", responseData.user.admin_id);
              localStorage.setItem("adminName", responseData.user.first_name);
              localStorage.setItem("adminEmail", responseData.user.email);
              localStorage.setItem("organization", responseData.user.organization_id);
              window.location.href = `/Admin`;
          }
  
          handleLoginResult(true);
          setState((prevState) => ({ ...prevState, error: '', isLoading: false }));
      } catch (error) {
        let errorMessage = '';
        if (error.response.status === 402) {
          const detail = error.response.data.detail;
          sessionStorage.setItem("token", `Bearer ${detail.access_token}`);
          localStorage.setItem("email", authData.username);
          localStorage.setItem('updatesPlan', detail.license_type);
          localStorage.setItem('updatesBill_type', detail.bill_type);
          setdetalsData(detail);
          if (detail.new_user){
              navigate('/payment', {state: { license_type : detail.license_type, bill_type : detail.bill_type, organization_id: detail.organization_id} });
          }else{
            setErrorMessage('License expired, upgrade to continue learning with Gamizign.');
            setPymentError(true);
          }
          
        }else{
          errorMessage = 'Invalid email or password'
        }
          setState((prevState) => ({ ...prevState, error: errorMessage, isLoading: false }));
          handleLoginResult(false);
          console.error('Error during login:', error);
      }
  };

    const togglePasswordVisibility = () => {
        setState((prevState) => ({ ...prevState, showPassword: !prevState.showPassword }));
    };

    const handleLoginResult = (isCorrectLogin) => {
        const inputs = document.querySelectorAll("input");

        inputs.forEach((input) => {
            input.readOnly = isCorrectLogin;
        });
    }
    // login  wih google
    const handleGoogleLogin = async (credentialResponse) => {
      logEvent('signin','login clicked','Login by google');
        var jwtToken = credentialResponse.credential.toString();
        setState((prevState) => ({ ...prevState, error: '', isLoading: true }));
        fetch(`${process.env.REACT_APP_ENDPOINT}/handle_token`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: jwtToken,
            }),
        })
            .then(response => response.json())
            .then(async responseData => {
              if (responseData.detail !== 'License expired') {
              const getCoursesCount = await axios.get(`${process.env.REACT_APP_ENDPOINT}/instructors/{instructor_id}/account/?email=${responseData.email}`, {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization':`Bearer ${responseData.access_token}`
                  }});
                const {firstName, lastName, email, instructor_id, organization_id, emailActivated, accountActive, admin_id, courses_count, questions_count, student_count} = getCoursesCount.data;
              // if (emailActivated) {
                const LearnerTab = admin_id ?  'hide' : 'show' ;
                const Onboarding = accountActive ?  2 : 0 ;
                localStorage.setItem("instructor_id", instructor_id);
                localStorage.setItem("admin_id", admin_id);
                localStorage.setItem("name", firstName);
                localStorage.setItem("lastname", lastName);
                localStorage.setItem("email", email);
                localStorage.setItem("organization", organization_id);
                localStorage.setItem("user_type", responseData.user_type);
                sessionStorage.setItem("token", `Bearer ${responseData.access_token}`);
                localStorage.setItem("LearnersList", LearnerTab);
                localStorage.setItem("accountActive", Onboarding);
                localStorage.setItem("plan", responseData.license.license_type);
                localStorage.setItem("coursesLength", courses_count);
                localStorage.setItem("qestionListsLength", questions_count);
                localStorage.setItem("studentsLenght", student_count);
                localStorage.setItem("emailActive", emailActivated ? emailActivated : "");
                localStorage.setItem("activatedTrial", responseData.license.activatedTrial ? responseData.license.activatedTrial : "");
                localStorage.setItem("tab", responseData.license.license_type === '3' ? 11 : 4);
                if (LearnerTab === 'show' && Onboarding == 0) {
                  localStorage.setItem("tab", 1);
                  window.location.href='/Dashboard/CreateCourse'
                }else if(LearnerTab === 'hide'){
                  if (questions_count<1) {
                    window.location.href = `/Dashboard/questionList`;
                    localStorage.setItem("tab", 2);
                  }else if (courses_count<1) {
                    window.location.href = `/Dashboard/CreateCourse`;
                    localStorage.setItem("tab", 1);
                    
                  }else{
                    window.location.href = `/Dashboard`;
                    localStorage.setItem("tab", 11);
                  }
                }
                setState((prevState) => ({ ...prevState, error: '', isLoading: false }));
            }else{
              handleLoginResult(false);
              setState((prevState) => ({ ...prevState, isLoading: false }));
              if (responseData.admin_id) {
                setErrorMessage('License expired please contact your administrator to continue learning with Gamizign.');
                setPymentError(true);
              }else{
                setErrorMessage('License expired, upgrade to continue learning with Gamizign.');
                setPymentError(true);
              }
            }
            })
            .catch(error => {
              if (error.response.status === 402) {
                const detail = error.response.data.detail;
                sessionStorage.setItem("token", `Bearer ${detail.access_token}`);
                localStorage.setItem('updatesPlan', detail.license_type);
                localStorage.setItem('updatesBill_type', detail.bill_type);
                setdetalsData(detail);
                if (detail.new_user){
                    navigate('/payment', {state: { license_type : detail.license_type, bill_type : detail.bill_type, organization_id: detail.organization_id} });
                }else{
                  setErrorMessage('License expired, upgrade to continue learning with Gamizign.');
                  setPymentError(true);
                }
                
              }else{
                const errorMessage = 'Error logging in with Google';
                setState((prevState) => ({ ...prevState, error: errorMessage, isLoading: false }));
                handleLoginResult(false);
                console.error('Error decoding JWT on the server:', error);
              }
            });
    }
    const [pymentError, setPymentError] = useState(false);
    const [ErrorMessage, setErrorMessage] = useState('');
    // Resend verification email
    const [ActiveEmail, setActiveEmail] = useState ({});
    const [Progrees, setProgrees] = useState (false);
    const resendVarification = async () => {
      setProgrees(true);
      const currentTime = new Date().getTime();
      const lastRequestTime = sessionStorage.getItem('lastRequestTime');
      if (lastRequestTime && currentTime - lastRequestTime < 3600000) {
        setErrorMessage('You requested activation email in the last hour, check you Mail spam folder or try after one hour.');
        setProgrees(false);
        return;
      }
      try {
        const response = await axios.post(`${process.env.REACT_APP_ENDPOINT}/send-activation-email`, ActiveEmail);
        if (response.status === 200) {
          setErrorMessage('Email has been resent');
          sessionStorage.setItem('lastRequestTime', currentTime);

        }
      } catch (error) {
        //console.log('An error occured sending activation email.');
        setErrorMessage('An error occured while resending email try again later.');
        setProgrees(false);

      } finally {
        setProgrees(false);
      }

    };
    const closeDialog = () =>{
      setPymentError(false);
      handleLoginResult(false); 
    }
    useEffect(() => {
      const currentLogo = logoRef.current; // Store the current value of the ref
      if (currentLogo) {
          // Listen for the end of the logo animation
        const handleAnimationEnd = () => {
          // Show login fields after logo animation completes
          setShowLoginFields(true);
        };
  
        // Add event listener to the logo animation
        currentLogo.addEventListener('animationend', handleAnimationEnd);
  
        // Cleanup function to remove event listener from the stored currentLogo element
        return () => {
          currentLogo.removeEventListener('animationend', handleAnimationEnd);
        };
      }
    }, [isSignUp]);
    return (
      // <Paper>
        <div style={{background:mode === 'light' ? '#F6F8FC' : 'white', backgroundImage:`url(${GMZbg})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}>
          <div className="z-10 absolute top-0 right-0 p-2">
          </div>
        <Dialog open={pymentError} onClose={closeDialog} disabled={Progrees}>
          <DialogContent>
              {Progrees && (
                <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-75 z-50">
                  <CircularProgress className="text-blue-800" />
                </div>
              )}
            <Alert severity={ErrorMessage === 'Congratulations your account has been created, Go into email to activate your account.' || ErrorMessage === 'Email has been resent' ? 'success' : 'error'}>
            {ErrorMessage === 'Kindly activate your account we sent you a verification email on this' ?<>{ErrorMessage} <b>{IncEmail}</b>.</> : ErrorMessage}
              </Alert>
          </DialogContent>
          {ErrorMessage === 'Kindly activate your account we sent you a verification email on this' || ErrorMessage === 'Congratulations your account has been created, Go into email to activate your account.' ? 
          <div className='flex justify-center'>
            <Button color="primary">
             <Link to='https://mail.google.com/mail/'>Check Mail</Link>
            </Button>
            <Button onClick={() => {resendVarification()}} color="primary">
              Resend
            </Button>
          </div>: ErrorMessage === 'Email has been resent'?
          <div className='flex justify-center'>
           <Button color="primary">
             <Link to='https://mail.google.com/mail/'>Check Mail</Link>
            </Button>

          </div>: ErrorMessage === 'License expired, upgrade to continue learning with Gamizign.'?
          <div className='flex justify-center'>
            <Button onClick={repay} color="primary">
              {/* <Link to={`/upgradeplan?email=${ActiveEmail.email}`}> */}
              Upgrade
              {/* </Link> */}
            </Button>
            <Button onClick={closeDialog} color="primary">
              Okay
            </Button>
          </div> : <div className='flex justify-center'>
            <Button onClick={closeDialog} color="primary">
              Okay
            </Button>
          </div>}
        </Dialog>
            {isSignUp ? (
            <div className="flex justify-center items-center">
              <div className="flex-1"></div>
                <div className="px-0 py-0">
                 <div className="flex justify-center"><img className="image" src={GamizignLogo} width={200} alt="LogoImage" /></div>
                    <HorizontalNonLinearStepper/>
                    <div className="flex justify-end"><button className="text-sky-500" onClick={()=>{setIsSignUp(false)}}>have an account?</button></div>
                </div>
                <div className="flex-1"></div>
            </div>
            ):(
                 <div className="flex justify-center items-center" style={{height:'fit-content',}}>
                 <div className="flex-1"></div>
                 <div className="flex-none SignIn text-center justify-center px-0 py-0" >
                        <div className="flex justify-center"><img className="logo" ref={logoRef}  src={GamizignLogo} width={200} alt="LogoImage" /></div>
                        <div className={`login-fields-container ${showLoginFields ? 'login-fields' : ''}`}>
                          <div className="drop-shadow-xl rounded-b-3xl bg-transparent relative">
                              {state.isLoading && (
                                  <div className="absolute inset-0 py-40 flex justify-center items-center bg-transparent bg-opacity-75 z-50">
                                      <CircularProgress className="text-blue-800" />
                                  </div>
                              )}
                              <form className="container p-3 px-5" action="post" onSubmit={handleSubmit}>
                              <TextField
                                          margin="dense"
                                          label="Email"
                                          name="email"
                                          type="email"
                                          required 
                                          placeholder="mohd@example.com"
                                          fullWidth
                                          sx={{
                                            marginTop: '20px',
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                  border: mode === 'light' ?  'none' : '',
                                                },
                                                '&.Mui-focused fieldset': {
                                                  border: '2px solid #4183d9',
                                                },
                                            },
                                        }}
                                          InputProps={{
                                              startAdornment: (
                                                  <InputAdornment position="start">
                                                      <EmailIcon  style={{color: mode === 'light' ? '#4183d9' : ''}}/>
                                                  </InputAdornment>
                                              ),
                                              style: {
                                                borderRadius: "10px",
                                                color: mode === 'light' ? '#4183d9' :'', background: mode === 'light' ? 'white' :'',
                                                fontSize:'20px',
                                              }
                                          }}
                                      />
                              <TextField
                                          margin="dense"
                                          label="Password"
                                          name="password"
                                          type={state.showPassword ? 'text' : 'password'}
                                          required 
                                          placeholder="Enter your password"
                                          fullWidth
                                          className=""
                                          sx={{
                                            marginTop: '20px',
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    border: mode === 'light' ?  'none' : '',
                                                },
                                                '&.Mui-focused fieldset': {
                                                  border: '2px solid #4183d9',
                                                },
                                            },
                                        }}
                                          InputProps={{
                                              startAdornment: (
                                                  <InputAdornment position="start">
                                                      <span onClick={togglePasswordVisibility} className="cursor-pointer">{state.showPassword ? <FaEye style={{color: mode === 'light' ? '#4183d9' : ''}} size={24}/> : <FaEyeSlash style={{color: mode === 'light' ? '#4183d9' : ''}} size={24}/>}</span>
                                                  </InputAdornment>
                                              ),
                                              style: {
                                                borderRadius: "10px",
                                                fontSize:'20px',
                                                color: mode === 'light' ? '#4183d9' :'', background: mode === 'light' ? 'white' :'',
                                              }
                                          }}
                                      />
                                  {state.error && <p style={{ color: 'red' }}>{state.error}</p>}
                                  <button className="py-1 flex justify-start font-bold ms-2 1mt-2" style={{color:'#4183d9'}} type="button"><Link to='/reset-password'>Forgot Password?</Link></button>
                                  <div className="flex justify-center items-center gap-1 py-1 mb-5"><img src={HeartIcon} alt="HeartIcon" /><p>Kindly!</p></div>
                                  <button className="btn GameOn hover:scale-110 text-2xl py-2 w-full" type="submit" disabled={state.isLoading}>
                                      <label><b>GAME ON!</b></label>
                                  </button>
      
                                  <GoogleOAuthProvider clientId="1071328084227-056krbch512vrpup6fgevl6j8i55g7ic.apps.googleusercontent.com">
                                      <GoogleLogin
                                          onSuccess={handleGoogleLogin}
                                          shape="circle"
                                          type="standard"
                                          logo_alignment="left"
                                          text="continue_with"
                                          theme="filled_blue"
                                          size="large"
                                          useOneTap
                                          disabled={state.isLoading} // Disable the button when loading
                                      />
                                  </GoogleOAuthProvider>
                          <button onClick={() => { setIsSignUp(true); navigate('/signup') }} className="btn transition-transform transform  hover:scale-110 py-2 w-full" type="button">
                            Create a new account
                          </button>
                              </form>
                          </div>
                          <p className="mt-4"><Link className="" target="_blank" to="https://gamizign.com/privacy-policy/">Privacy Policy & Terms of Use</Link></p>
                          <p className="mt-4"><Link className="" target="_blank" to="https://gamizign.com/refund-policy/">Refund & Cancellation Policy</Link></p>
                        </div>
                  
                 </div>
                 <div className="flex-1"></div>
             </div>
            )}
           
        </div>
        // </Paper>
    );

};
export default SignIn;
