import ReactGA from "react-ga4";

// Replace with your own Measurement ID
const MEASUREMENT_ID = "G-SR32T17JRX";

export const initGA = () => {
  ReactGA.initialize(MEASUREMENT_ID);
};
// export const logPageView = () => {
//     console.log('testing page');
    
//   ReactGA.send({ hitType: "pageview", page: window.location.pathname });
// };
export const logPageView = () => {
    if (window.gtag) {
      window.gtag("event", "page_view", {
        page_path: window.location.pathname,
        page_title: document.title,
      });
    }
  }

export const logEvent = (category, action, label) => {
  ReactGA.event({ category, action, label });
};